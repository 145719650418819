import "./Services.css";
import React, { useState } from "react";
import ServicesList from "./ServicesList";
import NavBar from "./../../common/navbar/NavBar";
import HeroBanner from "../../common/hero-banner/HeroBanner";

import PackageOne from "./packages/PackageOne";
import PackageThree from "./packages/PackageThree";
import PackageTwo from "./packages/PackageTwo";
import ServicesForm from "./ServicesForm";
import Footer from "../../common/Footer/Footer";
import PartnersFooter from "../../common/partners/PartnersFooter";
import Contact from "../../common/contact/Contact";
import { Helmet } from "react-helmet";
import { GetServiceContent } from "./../../helpers/ServiceContentHelper";

export default function Services() {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Services - Aeternal Paws</title>
        <meta
          name="description"
          content="Services of Aeternal Paws - A Pet Cremation Service in Bulacan & Mandaluyong City"
        />
      </Helmet>

      <NavBar ActiveButton={"services"} />
      <div className="servicespage">
        <HeroBanner
          subtitle={"Sanctuary services that deeply matters"}
          title={"Our Services"}
          desktopImage={"assets/services/services-desktop-hero.png"}
          mobileImage={"assets/services/services-mobile-hero.png"}
        />
        <div className="services-service-wrapper">
          <div className="services-service-image">
            <div className="services-service-content">
              <ServicesList
                setOpenModal={setOpenModal}
                openModal={openModal}
                content={GetServiceContent("plan")}
                img={"plan"}
                title="Aeternal Pet Life Planning"
                subtitle="Take the worry out of tomorrow. With the Aeternal Plan, you're ensuring that your beloved companion will always receive the care they deserve."
              />
              <ServicesList
                setModal={setOpenModal}
                openModal={openModal}
                img={"cremation"}
                content={GetServiceContent("cremation")}
                title="Pet Cremation"
                subtitle="Our commitment lies in providing compassionate and personalized private pet cremation services, ensuring that your cherished pets receive the highest level of care and the individual attention they truly deserve."
              />
              <ServicesList
                setOpenModal={setOpenModal}
                openModal={openModal}
                content={GetServiceContent("memorial")}
                img={"memorial"}
                title="Memorial Viewing"
                subtitle="Our team will ensure to offer a consoling memorial service for the fur families and their beloved pets who have embarked on to paradise."
              />
              <ServicesList
                setOpenModal={setOpenModal}
                openModal={openModal}
                content={GetServiceContent("personalize")}
                img={"personalized"}
                title="Personalized Keepsakes"
                subtitle="By choosing us, you choose the power of customization. We work closely with you to design a farewell that captures the essence of your pet's unique journey."
              />
              <ServicesList
                setModal={setOpenModal}
                openModal={openModal}
                img={"pickup"}
                content={GetServiceContent("pickup")}
                title="Companion Pick-up"
                subtitle="We offer a pick-up service for departed pets, whether it's from their home or the clinic where they peacefully passed away."
              />
              {/* <ServicesList
                img={"scatering"}
                title="Sea Scaterring Ceremony"
                subtitle="Through our sea scattering ceremony, we offer you a way to return your beloved pet’s ash remains to nature."
              /> */}
              <ServicesList
                setModal={setOpenModal}
                openModal={openModal}
                img={"photog"}
                content={GetServiceContent("photog")}
                title="Photography and Videography"
                subtitle="Our team will carefully document all the precious  final moments you share with your beloved pet to create a lasting memories for you to hold dear."
              />
              {/* <ServicesList
                img={"grief"}
                title="Grief Support"
                subtitle="After all the farewells and remaining sadness, our team of therapists is here to provide grief support to the fur families."
              /> */}
            </div>
          </div>
        </div>

        <div className="services-pricing-wrapper">
          <div className="services-pricing-image">
            <div className="services-pricing-header-content">
              <span className="services-pricing-header-title">
                Choose Your Plan
              </span>
              <span className="services-pricing-header-subtitle">
                Our Packages
              </span>
            </div>
            <div className="services-pricing-body-content">
              {/* <PackageOne /> */}
              <PackageTwo />
              <PackageThree />
            </div>
          </div>
        </div>
        <div className="services-contact-wrapper">
          <div className="services-contact-image">
            <div className="services-contact-header-content">
              <span className="services-contact-header-title">
                Get In Touch
              </span>
              <span className="services-contact-header-subtitle">
                Fill in the form and our team will get back to you promptly.
              </span>
            </div>
            <div className="services-contact-body-content">
              <ServicesForm />
            </div>
          </div>
        </div>

        <Contact />
        <PartnersFooter />
        <Footer />
      </div>
    </>
  );
}
