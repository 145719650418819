import "./Process.css";
import React, { useState } from "react";
import ProcessTiles from "./ProcessTiles";
import ProcessQuery from "./ProcessQuery";
import NavBar from "./../../common/navbar/NavBar";
import RowIcon from "../../common/row-icon/RowIcon";
import Contact from "../../common/contact/Contact";
import PartnersFooter from "../../common/partners/PartnersFooter";
import Footer from "../../common/Footer/Footer";
import HeroBanner from "./../../common/hero-banner/HeroBanner";
import { Helmet } from "react-helmet";

export default function Process() {
  const [activeFaq, setActiveFaq] = useState(0);

  const HandleSetActiveFaq = (id) =>
    activeFaq === id ? setActiveFaq(0) : setActiveFaq(id);

  const title = (
    <>
      Your Pet's Journey
      <span className="process-herobanner-title with-us"> with Us</span>
    </>
  );

  return (
    <>
      <meta
        name="description"
        content="Pet Cremation, Pet Crematorium, Pet Cremation in Bulacan, Pet Cremation in Mandaluyong"
      />
      <meta
        name="keywords"
        content="Pet Cremation in Bulacan, Pet Cremation in Mandaluyong, Pet Afterlife in Bulacan, animal afterlife in Bulacan, pet memorial services in Bulacan, Pet Afterlife in Bulacan"
      />

      <Helmet>
        <meta charSet="utf-8" />
        <title>Process - Aeternal Paws</title>
        <meta
          name="description"
          content="Process of Aeternal Paws - A Pet Cremation Service in Bulacan & Mandaluyong City"
        />
      </Helmet>

      <NavBar ActiveButton={"process"} />
      <div className="processpage">
        <HeroBanner
          subtitle={"Providing care, respect and assurance"}
          title={title}
          desktopImage={"assets/process/process-desktop-hero.png"}
          mobileImage={"assets/process/process-mobile-hero.png"}
        />

        <div className="process-process-wrapper">
          <div className="process-process-image">
            <img
              src="assets/process/process-desktop-arrows.png"
              title=""
              alt="aeternalpaws-banner"
              className="process-desktop-process-arrows"
            />

            <div className="process-process-content">
              <ProcessTiles
                img={"icon1"}
                number={"step1"}
                title={"Call Us"}
                subtitle={
                  "Call our 24/7 care line to discuss your wishes or arrangements."
                }
              />
              <ProcessTiles
                img={"icon2"}
                number={"step2"}
                title={"Transport"}
                subtitle={
                  "We offer the option to pick up your beloved pet at your home or veterinary clinic. Another option is to bring your companion to the store during operating hours."
                }
              />
              <ProcessTiles
                img={"icon3"}
                number={"step3"}
                title={"Identification"}
                subtitle={
                  "We've seamlessly integrated technology into our identification process to ensure your beloved pet's entire journey with us is comforting. You can expect real-time updates and continuous accessibility to our platforms available 24/7."
                }
              />
              <ProcessTiles
                img={"icon4"}
                number={"step4"}
                title={"Preparation"}
                subtitle={
                  "Upon receiving your beloved pet under our care, we take great care in preparing them with the utmost respect for their final memorial viewing alongside their family."
                }
              />
              <ProcessTiles
                img={"icon5"}
                number={"step5"}
                title={"Memorial Viewing"}
                subtitle={
                  "Our sanctuary provides a peaceful space where your pets can be viewed one last time, surrounded by family, in your final moments together."
                }
              />
              <ProcessTiles
                img={"icon6"}
                number={"step6"}
                title={"Ash Arrangement"}
                subtitle={
                  "In their last moments with us, we conduct a private cremation process with meticulous care and profound respect. To honor the beauty of their existence and allowing them to return gracefully to the embrace of nature."
                }
              />
              <ProcessTiles
                img={"icon7"}
                number={"step7"}
                title={"Collection"}
                subtitle={
                  "The process of collecting their ashes, carefully incorporating them into personalized keepsakes chosen with love by their pet parents."
                }
              />
              <ProcessTiles
                img={"icon8"}
                number={"step8"}
                title={"Additional Services"}
                subtitle={
                  "After the conlusion of our process, we offer additional personalized services and products hand-picked by the pet parents, allowing to create a distinct farewell and cherish lasting memories of their beloved companions."
                }
              />
              <ProcessTiles
                img={"icon9"}
                number={"step9"}
                title={"Beyond Care"}
                subtitle={
                  "Once a fur parent, always a fur parent. We understand the impact of losing a beloved pet, and our team will continue to provide support and assistance even after the process, ensuring that you're never alone during this difficult time."
                }
              />
            </div>
          </div>
        </div>

        <div className="process-query-wrapper">
          <div className="process-query-image">
            <div className="process-query-content">
              <div className="process-query-description-wrapper">
                <span className="process-query-description-title">
                  Still Got Queries?
                </span>
                <span className="process-query-description-subtitle">
                  Frequently Asked Questions
                </span>
              </div>
              <div className="process-query-queries-wrapper">
                <ProcessQuery
                  question={
                    "Can I have a private viewing of my pet before cremation?"
                  }
                  answer={
                    "Absolutely. We offer private viewings, allowing you to say your final goodbyes in a peaceful and intimate setting. You can also invite loved ones to the viewing and create a respectful farewell together."
                  }
                  id={1}
                  activeFaq={activeFaq}
                  setActive={HandleSetActiveFaq}
                />
                <ProcessQuery
                  question={
                    "What options do I have for storing my pet's ashes?"
                  }
                  answer={
                    "We offer a range of beautiful urns, keepsakes, and memorial items. You can choose one that reflects your pet's unique personality and your preferences."
                  }
                  id={2}
                  activeFaq={activeFaq}
                  setActive={HandleSetActiveFaq}
                />
                <ProcessQuery
                  question={
                    "How can I be assured that I'll receive my pet's ashes?"
                  }
                  answer={
                    "Rest assured that accuracy in providing you with the right ashes is of utmost importance to us. Every pet is assigned with a distinct identification that accompanies them throughout the entire journey. Our system ensures that this ID is tagged and tracked, safeguarding the integrity of the process."
                  }
                  id={3}
                  activeFaq={activeFaq}
                  setActive={HandleSetActiveFaq}
                />
                <ProcessQuery
                  question={
                    "How do I know when my pet's ashes will be ready for collection?"
                  }
                  answer={
                    "Our automated system, Paw Tracker, will send email notifications to keep you updated on the progress of your beloved pet's journey. After which, you can arrange a suitable time for the retrieval of your pet's ashes or opt for convenient delivery, as per your choice. "
                  }
                  id={4}
                  activeFaq={activeFaq}
                  setActive={HandleSetActiveFaq}
                />

                <ProcessQuery
                  question={"What if my pet passes away at home?"}
                  answer={
                    "We offer a collection service where we can respectfully pick up your pet from your home or veterinarian's office, ensuring a smooth process during this difficult time."
                  }
                  id={6}
                  activeFaq={activeFaq}
                  setActive={HandleSetActiveFaq}
                />
                <ProcessQuery
                  question={
                    "How can I keep my pet's memory alive after cremation?"
                  }
                  answer={
                    "Apart from traditional urns, we offer various memorabilia like portraits, trinkets and personalized keepsakes that allow you to cherish the memory of your beloved pet. We also invite you to engage in volunteering with animal-related organizations, extending your compassion to those who require assistance."
                  }
                  id={7}
                  activeFaq={activeFaq}
                  setActive={HandleSetActiveFaq}
                />
                <ProcessQuery
                  question={
                    "Are you licensed and accredited for pet cremation services?"
                  }
                  answer={
                    "Yes, we are licensed and adhere to the highest standards in pet cremation practices, ensuring your pet is treated with the dignity they deserve."
                  }
                  id={8}
                  activeFaq={activeFaq}
                  setActive={HandleSetActiveFaq}
                />
              </div>
            </div>
          </div>
        </div>

        <RowIcon
          titleSmall={"Our Technology"}
          title={"Paw Tracker"}
          ic1={"process-desktop-pawtracker-icon1"}
          lbl1={"Provides real time data to the owner"}
          ic2={"process-desktop-pawtracker-icon2"}
          lbl2={"Notifies timely update in the email"}
          ic3={"process-desktop-pawtracker-icon3"}
          lbl3={"Photos are stored in the cloud"}
          ic4={"process-desktop-pawtracker-icon4"}
          lbl4={"Free of subscription"}
          ic5={"process-desktop-pawtracker-icon5"}
          lbl5={"Accessible any time on any devices"}
        />
        <Contact />
        <PartnersFooter />
        <Footer />
      </div>
    </>
  );
}
