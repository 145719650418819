import React from "react";
import "./LandingPage.css";
import { useNavigate } from "react-router-dom";
import NavBar from "../../common/navbar/NavBar";
import Footer from "../../common/Footer/Footer";
import Contact from "../../common/contact/Contact";
import PartnersFooter from "../../common/partners/PartnersFooter";
import RowIcon from "./../../common/row-icon/RowIcon";
import LandingPageServices from "./LandingPageServices";
import { Helmet } from "react-helmet";
import { ReactGoogleReviews } from "react-google-reviews";
import "react-google-reviews/dist/index.css";

export default function HomePage() {
  const navigate = useNavigate();

  const HandleReadMoreRedirect = () => {
    navigate("/about");
    window.scrollTo(0, 0);
  };

  function Reviews() {
    // Create a free Featurable account at https://featurable.com
    // Then create a new Featurable widget and copy the widget ID
    const featurableWidgetId = "4e5b8dcd-7af1-4ee5-9db5-e6ff1351d938";

    return (
      <ReactGoogleReviews layout="carousel" featurableId={featurableWidgetId} />
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Aeternal Paws - Pet Afterlife Services</title>
        <meta
          name="description"
          content="Pet Cremation, Pet Crematorium, Pet Cremation in Bulacan, Pet Cremation in Mandaluyong"
        />
      </Helmet>

      <NavBar ActiveButton="home" />
      <div className="homepage">
        <div className="homepage-herobanner-wrapper">
          <div className="homepage-herobanner-image">
            <img
              src="assets/homepage/homepage-desktop-hero-banner.png"
              title=""
              alt="aeternalpaws-banner"
              className="homepage-desktop-herobanner-image"
            />
            <img
              src="assets/homepage/homepage-mobile-hero-banner.png"
              title=""
              alt="aeternalpaws-banner"
              className="homepage-mobile-herobanner-image"
            />
            <div className="homepage-herobanner-content">
              <span className="homepage-herobanner-title">
                Redefining Pet Afterlife Services
              </span>
              <h1 className="homepage-herobanner-subtitle">
                Pet Cremation & Memorial Services
              </h1>
              <h2 className="homepage-herobanner-description">
                Helping pet families create a heartfelt farewell where every
                pet's memories live on with love and care
              </h2>
              <a href="tel:+639952610478">
                <button title="Speak to Us">SPEAK TO US</button>
              </a>
            </div>
          </div>
        </div>
        <div className="homepage-aboutus-wrapper">
          <div className="homepage-aboutus-image">
            <div
              class="elfsight-app-215c4d2b-3fb9-441e-87d9-97ec148c4383"
              data-elfsight-app-lazy
            ></div>
          </div>
        </div>
        <div className="homepage-aboutus-wrapper">
          <div className="homepage-aboutus-image">
            <div className="homepage-aboutus-content-wrapper">
              <img
                src="assets/homepage/homepage-desktop-aboutus-banner.png"
                title=""
                alt="aeternalpaws-aboutus"
                className="homepage-desktop-aboutus-image"
              />
              <div className="homepage-aboutus-content">
                <span className="homepage-aboutus-title">ABOUT US</span>
                <span className="homepage-aboutus-subtitle">
                  A lifetime end-of-journey treasure
                </span>

                <span className="homepage-aboutus-description">
                  When the time comes that we need to write the final chapter of
                  our beloved pet’s life, it should be as caring, loving, and
                  unique as they are. They deserve a narrative that mirrors
                  their tenderness, affection, and individuality.
                  <br />
                  <br />
                  At Aeternal Paws, we support pet owners through their best
                  friend’s journey with professional and compassionate services
                  while preserving their memory through keepsakes. Our objective
                  is to provide respectful and meaningful pet cremation services
                  for your beloved pets, to honor their memorial before going to
                  their final resting place.
                </span>
                <button onClick={HandleReadMoreRedirect}>READ MORE</button>
              </div>
            </div>
          </div>
        </div>
        <div className="homepage-services-wrapper">
          <div className="homepage-services-image">
            <div className="homepage-services-titlebox">
              <span className="homepage-services-titlebox-title">
                Our Services
              </span>
              <span className="homepage-services-titlebox-subtitle">
                Sanctuary services that matter to every fur family
              </span>
            </div>

            <div className="homepage-services-serviceslist">
              <LandingPageServices
                title="Aeternal Pet Life Planning"
                subtitle="Your pet's future, your peace of mind. Take the first step in securing their legacy today and let us help you create a lasting tribute to your furry family member."
                PerformRedirect={true}
              />
              <LandingPageServices
                title="Pet Cremation"
                subtitle="With the deepest compassion and care, we will oversee every step of the process, ensuring your departed pets are handled with the utmost respect throughout their journey with us."
                PerformRedirect={true}
              />
              <LandingPageServices
                title="Memorial Viewing"
                subtitle="At our sanctuary, we've taken meticulous attention to details to create an environment that reflects the serenity and solemnity of your pet's departure."
                PerformRedirect={true}
              />
              <LandingPageServices
                title="Personalized Keepsakes"
                subtitle="Our commitment to customization ensures that your pet's farewell is a personal and meaningful experience. Take home a piece or two that will remind you of their lasting love. "
                PerformRedirect={true}
              />
              <LandingPageServices
                title="Companion Pick-up"
                subtitle="Your pet's journey to our sanctuary begins with our pick-up service, providing peace of mind and assistance during this difficult time."
                PerformRedirect={true}
              />
              <LandingPageServices
                title="Photography and Videography"
                subtitle="Every moment with your beloved pet is special, and our team is here to capture those moments with skill and tenderness, something you can revisit whenever and wherever you wish."
                PerformRedirect={true}
              />

              {/* <LandingPageServices
                title="Grief Support"
                subtitle="Here at Aeternal Paws, we will provide a support system to the fur families. "
                PerformRedirect={true}
              /> */}

              {/* <LandingPageServices
                title="Sea Scattering Ceremony"
                subtitle="We offer a way to return your beloved pet’s ash remains to nature through sea scattering."
                PerformRedirect={true}
              /> */}
            </div>
          </div>
          <img
            src="assets/homepage/homepage-desktop-services-banner.png"
            title=""
            alt="aeternalpaws-services"
            className="homepage-desktop-services-image"
          />
        </div>

        <div className="homepage-process-wrapper">
          <div className="homepage-process-image">
            <div className="homepage-process-titlebox">
              <span className="homepage-process-titlebox-title">
                Your Pet's Journey
                <span className="homepage-process-titlebox-title1">
                  with us
                </span>
              </span>
              <span className="homepage-process-titlebox-subtitle">
                Providing care, affection and certainty
              </span>
            </div>
            <img
              src="assets/homepage/homepage-desktop-process-banner.png"
              title=""
              alt="aeternalpaws-process"
              className="homepage-desktop-process-image"
            />
            <img
              src="assets/homepage/homepage-mobile-process-banner.png"
              title=""
              alt="aeternalpaws-process"
              className="homepage-desktop-process-image mobile"
            />
          </div>
        </div>

        <RowIcon
          title={"Our Vision"}
          ic1={"farewell"}
          lbl1={"Personalized Farewell Experience"}
          ic2={"laptop"}
          lbl2={"Compassionate Innovation"}
          ic3={"hand"}
          lbl3={"Empathetic Support System"}
          ic4={"nose"}
          lbl4={"Unwavering Dedication"}
          ic5={"dog"}
          lbl5={"Pioneering Pet Care Excellence"}
        />

        <Contact />
        <PartnersFooter />
        <Footer />
      </div>
    </>
  );
}
