import "./Contact.css";
import React from "react";
import ContactIcon from "./ContactIcon";

export default function Contact() {
  return (
    <div className="common-contact-wrapper">
      <div className="common-contact-image">
        <iframe
          src="https://www.google.com/maps/d/embed?mid=15OXOVfnsR59l_ZHzajaepzFHhaJSi1E&ehbc=2E312F"
          allowFullScreen={false}
          loading="lazy"
          title="aeternalpaws-map"
          referrerPolicy="no-referrer-when-downgrade"
          className="common-contact-map"
        />

        <div className="common-contact-content">
          <span className="common-contact-title">Visit Us</span>

          <span className="common-contact-description">
            Located in the heart of the city - you'll find our sanctuary, always
            open and prepared to welcome your companions with open arms.
          </span>

          <span className="common-contact-socialmediaicon">
            <a
              href="https://www.facebook.com/aeternalpaws/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/common/contact/contact-desktop-visit-fb-icon.svg"
                title=""
                alt="aeternalpaws-facebook"
                className="common-contact-socialmedia-image"
              />
            </a>
            <a
              href="https://www.instagram.com/AeternalPaws/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/common/contact/contact-desktop-visit-ig-icon.svg"
                title=""
                alt="aeternalpaws-instagram"
                className="common-contact-socialmedia-image"
              />
            </a>
            <a
              href="https://www.tiktok.com/@AeternalPaws/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/common/contact/contact-desktop-visit-tiktok-icon.svg"
                title=""
                alt="aeternalpaws-instagram"
                className="common-contact-socialmedia-image"
              />
            </a>
          </span>

          <ContactIcon
            description={
              <>
                <strong>Bulacan - </strong> MacArthur Hwy, Brgy. Tikay, Malolos
                Bulacan
                <br />
                <strong>Mandaluyong - </strong> Boni Avenue, Mandaluyong City
              </>
            }
            // description="MacArthur Hwy, Brgy. Tikay, Malolos Bulacan"
            img="address"
          />
          <ContactIcon
            description={
              <>
                <strong>Bulacan - </strong>{" "}
                <a href="tel:+639952610478">0995-261-0478</a>
                <br />
                <strong>Mandaluyong - </strong>{" "}
                <a href="tel:+639952610478">0995-261-0478</a>
              </>
            }
            img="phone"
          />
          <ContactIcon description="inquiry@aeternalpaws.com" img="mail" />

          <ContactIcon
            title={
              <>
                <strong>Mon-Fri:</strong> 7am – 10pm
              </>
            }
            description={
              <>
                <strong>Weekends & Holidays:</strong> 7am – 10pm
              </>
            }
            img="time"
          />
        </div>
      </div>
    </div>
  );
}
