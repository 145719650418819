import "./About.css";
import React from "react";
import NavBar from "../../common/navbar/NavBar";
import AboutImageSlide from "./AboutImageSlide";
import AboutChooseUsImgHolder from "./AboutChooseUsImgHolder";
import AboutCarousel from "./AboutCarousel";
import Contact from "../../common/contact/Contact";
import PartnersFooter from "../../common/partners/PartnersFooter";
import Footer from "../../common/Footer/Footer";
import { AboutImgs } from "./AboutImg";
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <>
      <meta
        name="description"
        content="Pet Cremation, Pet Crematorium, Pet Cremation in Bulacan, Pet Cremation in Mandaluyong"
      />
      <meta
        name="keywords"
        content="Pet Cremation in Bulacan, Pet Cremation in Mandaluyong, Pet Afterlife in Bulacan, animal afterlife in Bulacan, pet memorial services in Bulacan, Pet Afterlife in Bulacan"
      />

      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - Aeternal Paws</title>
        <meta
          name="description"
          content="About Aeternal Paws - Pet Cremation Service in Bulacan & Mandaluyong City"
        />
      </Helmet>

      <NavBar ActiveButton={"about"} />
      <div className="aboutpage">
        <div className="about-herobanner-wrapper">
          <div className="about-herobanner-image">
            <div className="about-herobanner-content">
              <h2 className="about-herobanner-title">About Us</h2>
              <h1 className="about-herobanner-subtitle">Austine's Story</h1>
              <span className="about-herobanner-description">
                Losing Austine felt like the conclusion of a beautiful new
                chapter. Our journey as a close-knit family of three earnestly
                pursuing our dreams had only begun. Austine, our cherished
                four-legged companion, had been a constant presence witnessing
                our transformation from modest beginnings. As his devoted fur
                parents, we had promised to provide him with the world,
                reciprocating the endless love he had given us when we had so
                little. Yet, a heartbreaking twist of fate took him from us just
                as the pieces of our lives were falling into place.
              </span>
            </div>
            <div className="about-herobanner-images-content">
              <AboutCarousel images={AboutImgs.Austine} interval={2500} />
            </div>
          </div>
        </div>
        <div className="about-history-wrapper">
          <div className="about-history-image">
            <div className="about-history-content">
              <div className="about-history-content-history">
                <span className="about-history-title">Our History</span>
                <span className="about-history-description">
                  The seeds of our business were sown after an unexpected loss,
                  when we bid farewell to our beloved pet. In his memory, we
                  launch on a journey to create a business that would not only
                  serve as a tribute but also as a way to reciprocate the
                  boundless kindness our pet once showered upon us. Austine, our
                  source of inspiration, remains at the heart of our venture.
                  <br />
                  <br />
                  With a clear intent to elevate the standards of the pet
                  afterlife industry, we founded Aeternal Paws. Our approach
                  integrates industry's best practices, cutting-edge technology,
                  and the invaluable lessons we've learned through our own
                  experiences.
                </span>

                <AboutImageSlide
                  imageName={"about-history-history1"}
                  fullImgHeight={false}
                  RightControlPosition={false}
                />
              </div>
              <div className="about-history-content-team">
                <div className="about-history-content-team-image-wrapper">
                  <AboutImageSlide
                    imageName={"about-history-team1"}
                    fullImgHeight={false}
                    RightControlPosition={true}
                  />
                </div>
                <div className="about-history-content-team-wrapper">
                  <span className="about-history-title team">Our Team</span>
                  <span className="about-history-description">
                    At Aeternal Paws, the team assimilates Austine’s belief of
                    treating everyone as family, holding them close to our
                    hearts. Our dedicated team ensures our mission to provide
                    support to a meaningful farewell for your beloved pets. The
                    team takes pride in goodwill by donating, volunteering, and
                    actively participating in non-profit organizations focused
                    on supporting, protecting, and promoting animal welfare
                    rights. {<br />}
                    {<br />}It is more than just a commitment to transcending
                    animal welfare; it’s about uplifting the overall well-being
                    of the lovely animals we coexist with.
                    {/* {<br />} {<br />}{" "}
                    “From the initial steps to the final moments with your
                    beloved pets, we’re here to support and stand by you. So, as
                    you reminisce, we hope our curated prints and keepsakes will
                    help you keep the precious memories alive.” {<br />} - From
                    the Aeternal Paws Family */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-accreditation-wrapper">
          <img
            src="assets/about/about-desktop-accredited-curve.png"
            title=""
            alt="curve"
            className="about-accreditation-curve"
          />
          <div className="about-accreditation-image">
            <div className="about-accreditation-content">
              <div className="about-accreditation-content-wording">
                <span className="about-accreditation-title">
                  Internationally Accredited
                </span>
                <span className="about-accreditation-subtitle">
                  We proudly hold the distinction of being a member and
                  internationally recognized and accredited by the International
                  Cemetery, Cremation, and Funeral Association (ICCFA) and Pet
                  Loss Professionals Alliance (PLPA). Additionally, our
                  crematory facility is committed to strict adherence to all
                  local government agency regulations and standards.
                </span>

                <img
                  src="assets/about/about-accreditation-quote.png"
                  title=""
                  alt="accreditation-quote"
                  className="about-accreditation-quote"
                />

                <span className="about-accreditation-description">
                  Partnering with Aeternal Paws has been a game-changer for our
                  practice. Their unwavering dedication to compassionate care
                  has left a lasting impression on our clients, making it easy
                  for us to confidently recommend their services.
                </span>

                <div className="about-accreditation-content-testimony">
                  <img
                    src="assets/about/about-accreditation-consultant.png"
                    title=""
                    alt="accreditation-consultant"
                    className="about-accreditation-consultant"
                  />
                  <div className="about-accreditation-content-testimony-content">
                    <span className="about-consultant-title">Yna Calonzo</span>
                    <span className="about-consultant-description">
                      Clinic Consultant
                    </span>
                  </div>
                </div>
              </div>
              <div className="about-accreditation-content-image">
                <AboutCarousel images={AboutImgs.Acredited} interval={1500} />
              </div>
            </div>
          </div>
        </div>
        <div className="about-contact-wrapper">
          <div className="about-contact-image">
            <div className="about-contact-content">
              <img
                src={`assets/about/about-accreditation-moving-arrow.png`}
                title=""
                alt="history-banner"
                className={`aboutus-contact-image`}
              />
              <span className="about-contact-title">Serving you 24/7!</span>
              <span className="about-contact-subtitle">
                For a lasting tribute to your beloved companion.
              </span>
            </div>
            <div className="about-contact-button">
              <a
                aria-label="Chat on WhatsApp"
                href="https://wa.me/639952610478"
              >
                <div className="about-contact-button-whatsapp">
                  <img
                    src={`assets/about/about-desktop-cta-whatsapp.png`}
                    title=""
                    alt="history-banner"
                    className={`aboutus-contact-buttonimage`}
                  />
                  <span className="about-contact-button-label">
                    WhatsApp Us
                  </span>
                </div>
              </a>

              <a href="tel:+639952610478">
                <div className="about-contact-button-number">
                  <img
                    src={`assets/about/about-desktop-cta-call.png`}
                    title=""
                    alt="history-banner"
                    className={`aboutus-contact-buttonimage`}
                  />

                  <span className="about-contact-button-label">
                    0995-261-0478
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="about-chooseus-wrapper">
          <div className="about-chooseus-image">
            <div className="about-chooseus-title-content">
              <span className="about-chooseus-title">Why choose us?</span>
              <span className="about-chooseus-subtitle">
                The Aeternal Process
              </span>
            </div>
            <div className="about-chooseus-image-content-wrapper">
              <AboutChooseUsImgHolder
                icon={"about-desktop-whychoose-icon1"}
                title={"Personalized Farewell"}
                subtitle={
                  "We believe each pet has a unique tale. Our team arrange a personalized farewell that mirrors the joy and companionship your pets brought into your life. From their final moments to crafting cherished memories, we ensure their legacy thrives in a touching and individualized way."
                }
              />
              <AboutChooseUsImgHolder
                icon={"about-desktop-whychoose-icon2"}
                title={"Innovative Care"}
                subtitle={
                  "We merge tradition with innovation by embracing technology. Our efficient processes and real-time updates keep you engaged and informed throughout the entire journey. We seamlessly blend age-old customs with contemporary solutions, granting your pets a farewell that's both timeless and modern."
                }
              />
              <AboutChooseUsImgHolder
                icon={"about-desktop-whychoose-icon3"}
                title={"Filipino Values; Global Standards"}
                subtitle={
                  "As a proudly Filipino-made business, our values reflect the warmth of our culture. We uphold international pet care standards, aligning our practices with global excellence. Rooted in a deep affection for animals, we deliver a service that speaks of our commitment and love."
                }
              />
            </div>
          </div>
        </div>
        <div className="about-carousel-wrapper">
          <div className="about-carousel-image">
            <div className="about-carousel-title-content">
              <span className="about-carousel-title">Our Clinic</span>
              <span className="about-carousel-subtitle">
                Aeternal Paws{" "}
                <span className="about-carousel-subtitle1">
                  Pet Afterlife Services
                </span>
              </span>
              <span className="about-carousel-description">
                Our clinic embodies industry-leading standards - meticulously
                designed with a heartfelt commitment to ensure a profoundly
                memorable farewell experience for pet families.
              </span>
            </div>
            <div className="about-carousel-image-content-wrapper">
              <AboutCarousel
                images={AboutImgs.OurClinic}
                WithIndicators={true}
              />
            </div>
          </div>
        </div>

        <Contact />
        <PartnersFooter />
        <Footer />
      </div>
    </>
  );
}
